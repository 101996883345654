import React, { Suspense }              from 'react';
import { BrowserRouter, Route, Routes } from 'react-router-dom';

const Index = React.lazy ( () => import('./pages/Index') );
const Privacy = React.lazy ( () => import('./pages/Privacy') );
const Terms = React.lazy ( () => import('./pages/Terms') );
const NotFoundPage = React.lazy ( () => import('./pages/NotFound') );

function App() {
	return (
		<>
			<Suspense fallback={
				<div className={ 'h-screen w-screen flex justify-center items-center bg-bgLight' } >
					<p className={ 'text-center text-primary text-2xl lg:text-9xl font-semibold' } >Loading...!</p >
				</div >
			} >
				<BrowserRouter >
					<Routes >
						<Route path={ '/' } element={ <Index /> } />
						<Route path={ '/privacy' } element={ <Privacy /> } />
						<Route path={ '/terms' } element={ <Terms /> } />
						<Route path="*" element={ <NotFoundPage /> } />
					</Routes >
				</BrowserRouter >
			</Suspense >
		</>
	);
}

export default App;
